import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import SEO from "../components/globals/seo";
import Layout from "../components/containers/layout";
import Section from "../components/containers/section";
import Container from "../components/containers/container";
import Flex from "../components/containers/flex";
import Box from "../components/containers/box";
import BlogBanner from "../components/banners/blogBanner";
import BlogList from "../components/blog/blogList";
import BlogListItem from "../components/blog/blogListItem";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Link from "../components/links/link";
import Image from "../components/globals/image";
import { buildDate } from "../utils/helperFunctions";

const ActiveImgWrap = styled(Box)`
  img {
    width: 100%;
    border-radius: ${p => p.theme.borderRadiusSqBtn};
  }
`;

const ActivePost = styled(Link)`
  transition: ${p => p.theme.transitionDefault};

  :hover {
    cursor: pointer;

    div:last-of-type {
      color: ${p => p.theme.greenDark};
    }
  }
`;

const DateWrap = styled(Box)`
  color: ${p => p.theme.greyDark};
  font-size: ${p => p.theme.smallFontSize};
`;

const ImgCrop = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */

  > * {
    /* important needed to override default Gatsby image inline styles */
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const BlogPage = ({ theme, data }) => {
  // The post are ordered by date during graphql query below
  const activePost = data.allPrismicBlog.edges[0];
  const restOfPosts = data.allPrismicBlog.edges.filter((post, i) => i > 0);

  return (
    <Layout navbarPadOnly>
      <SEO
        title="Blog"
        description="Find all the latest stories on TopTekkers!"
      />

      {/* Banner */}
      <BlogBanner>
        <Container>
          <Heading
            as="h1"
            color={theme.white}
            fontSize={[`38px`, null, `48px`]}
            mb="30px"
          >
            Blog
          </Heading>
        </Container>
      </BlogBanner>

      {/* Highlight */}
      <Section pt={[30, null, 87]} pb={[40, null, 70]}>
        <Container>
          <ActivePost to={`/blog/${activePost.node.uid}`}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexDirection={[`column`, null, `row`]}
              alignItems="flex-start"
            >
              {/* content */}
              <Box
                mr={[0, null, 30]}
                mb={[20, null, 0]}
                width={[`100%`, null, `40%`]}
                maxWidth={[null, null, 474]}
                order={[2, null, 1]}
              >
                <DateWrap mb={[`20px`, null, `26px`]}>
                  {activePost.node.data.date
                    ? buildDate(activePost.node.data.date)
                    : null}
                </DateWrap>
                <Heading as="h2" fontSize="30px" lineHeight="38px">
                  {activePost.node.data.title.text}
                </Heading>
                <Text>{activePost.node.data.content.raw[0].text}</Text>
                <Text color={theme.green} fontSize={theme.baseFontSizeTabMob}>
                  Read more
                </Text>
              </Box>

              {/* image */}
              <ActiveImgWrap
                width={[`100%`, null, `60%`]}
                mt={[`-70px`, null, 0, `-120px`]}
                mb={[`30px`, null, 0]}
                order={[1, null, 2]}
              >
                <Image
                  fluid={
                    activePost.node.data.image.localFile.childImageSharp.fluid
                  }
                  alt={activePost.node.data.image.alt}
                />
              </ActiveImgWrap>
            </Flex>
          </ActivePost>
        </Container>
      </Section>

      <Section>
        <Container>
          <BlogList>
            {restOfPosts.map((post) => {
              const { fluid } = post.node.data.image.localFile.childImageSharp;

              return (
                <BlogListItem key={post.node.id}>
                  <Link
                    to={`/blog/${post.node.uid}`}
                    color={theme.green}
                    fontSize={theme.baseFontSizeTabMob}
                  >
                    <ImgCrop>
                      <Image fluid={fluid} alt={post.node.data.image.alt} />
                    </ImgCrop>

                    <DateWrap pt="30px">
                      {post.node.data.date
                        ? buildDate(post.node.data.date)
                        : null}
                    </DateWrap>

                    <Heading as="h2" fontSize="30px" lineHeight="38px">
                      {post.node.data.title.text}
                    </Heading>

                    <span>Read more</span>
                  </Link>
                </BlogListItem>
              );
            })}
          </BlogList>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicBlog(sort: { fields: data___date, order: DESC }, filter: {data: {hide: {ne: true}}}) {
      edges {
        node {
          uid
          data {
            date
            title {
              text
            }
            content {
              raw {
                text
              }
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 370) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(BlogPage);
