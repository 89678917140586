import React from "react"
import styled from "styled-components"

const BlogListItem = styled.li`
  position: relative;
  width: 100%;
  margin-bottom: 54px;
  padding-bottom: 0;
  transition: ${p => p.theme.transitionDefault};

  h2 {
    margin-top: 16px;
    margin-bottom: 44px;
  }

  span {
    position: absolute;
    bottom: 0;
  }

  @media (min-width: ${p => p.theme.screenS}) {
    width: 48%;
  }
  @media (min-width: ${p => p.theme.screenL}) {
    width: 30%;
  }

  :hover {
    span {
      color: ${p => p.theme.greenDark};
    }
  }
`
export default BlogListItem
