import React from "react"
import styled from "styled-components"

const BlogList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export default BlogList
