import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"

import Banner from "../containers/banner"
import Circle from "../globals/circle"
import MaxWidthAbsolute from "../containers/maxWidthAbsolute"

const BlogBanner = ({ children, theme }) => {
  return (
    <Banner
      bg={theme.green}
      pt={[46, null, 87]}
      px={[30, 80, null, 200]}
      pb={[30, null, null, 60]}
      minHeight={[`200px`, null, `240px`]}
    >
      <MaxWidthAbsolute>
        <Circle
          grow
          bgColor={theme.blueDark}
          opacity="0.1"
          bottom={[60, null, -46, -90]}
          left={[-30, null, -46, -90]}
        />
        <Circle
          size="l"
          bgColor={theme.blueDark}
          opacity="0.1"
          top={[-90, null, -46, -90]}
          left="24%"
        />
        <Circle
          size="l"
          bgColor={theme.blueDark}
          opacity="0.1"
          bottom={[-90, null, -46, -90]}
          right="18%"
        />
      </MaxWidthAbsolute>

      {children}
    </Banner>
  )
}

BlogBanner.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(BlogBanner)
